import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import get from 'lodash/get'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import Layout from '../components/layout/layout'
import Container from '../components/layout/container'
import { BREAKPOINTS } from '../styles/cssVariables'
import SEO from '../components/layout/seo'

const PageContainer = styled(Container)`
  padding-bottom: 200px;

  @media (min-width: ${BREAKPOINTS.sml}px) {
    padding-bottom: 150px;
  }

  p {
    margin-bottom: 1em;
  }
`

const PageTitle = styled.h1`
  padding: 85px 0;

  @media (min-width: ${BREAKPOINTS.sml}px) {
    padding: 50px 0;
  }
`

const ContentLink = styled.a`
  font-weight: bold;
  color: var(--color-heading);
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

const SectionContainer = styled.section`
`

/**
 * Renders a basic rich text page detailing this site's terms of service.
 */
const TermsOfServicePage = (props) => {
  const page = get(props, 'data.contentfulPageTermsConditions.termsConditions')
  const seoTitle = get(this, 'props.data.contentfulPageTermsConditions.seoTitle')
  const seoDescription = get(this, 'props.data.contentfulPageTermsConditions.seoDescription')

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="small">{children}</p>
      ),
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,

      [INLINES.HYPERLINK]: (node, children) => (
        <ContentLink className="small" href={node.data.uri}>
          {children}
        </ContentLink>
      ),
    },
  }

  return (
    <Layout location={props.location}>
      <SEO 
        pageTitle={seoTitle} 
        seoDescription={seoDescription} 
      />
      <SectionContainer id="terms">
        <PageContainer width={12}>
          <PageTitle>Terms of Service</PageTitle>

          {renderRichText(page, options)}
        </PageContainer>
      </SectionContainer>
    </Layout>
  )
}

export default TermsOfServicePage

export const termsOfServicePageQuery = graphql`
  query termsOfServicePageQuery {
    contentfulPageTermsConditions {
      seoTitle
      seoDescription
      termsConditions {
        raw
      }
    }
  }
`
